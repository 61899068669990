<template>
  <b-card-code no-body>
    <b-card-body>
      <!-- filter -->
      <b-form-group
        label-cols-sm="10"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-2"
      >
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="输入搜索内容"
          />
        </b-input-group>
      </b-form-group>

      <b-table
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
        :striped="striped"
        :bordered="bordered"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #cell(SeriesName)="data">
          <h-link
            :id="data.item.sid"
            :text="data.item.SeriesName"
            to="series-details"
            type="series"
          />
        </template>

        <template #cell(RaceCategory)="data">
          <span class="text-nowrap">{{ data.item.RaceCategory }}</span>
        </template>

        <template #cell(Year)="data">
          <span class="text-nowrap">{{ data.item.Year }}</span>
        </template>

        <template #cell(Season)="data">
          <span class="text-nowrap">{{ getSeacon(data.item.Season) }}</span>
        </template>

        <template #cell(StageCount)="data">
          <span class="text-nowrap">{{ data.item.StageCount }}</span>
        </template>

        <template #cell(start)="data">
          <span class="text-nowrap">{{ data.item.start }}</span>
        </template>

        <template #cell(close)="data">
          <span class="text-nowrap">{{ getCloseData(data.item.close, data.item.Status) }}</span>
        </template>

        <template #cell()="data">
          {{ data.item }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap">

        <!-- page length -->
        <b-form-group
          label="每页显示："
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </b-card-body>
  </b-card-code>
</template>

<script>
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import HLink from '@/views/common/h-link.vue'
import {
  BTable, BCardBody, BFormSelect, BPagination, BFormGroup, BInputGroup,
  BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BFormSelect,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    HLink,
  },
  data() {
    return {
      fields: [
        { key: 'SeriesName', label: '联赛名称', sortable: true },
        { key: 'RaceCategory', label: '赛事系列', sortable: true },
        { key: 'Year', label: '年份', sortable: true },
        { key: 'Season', label: '比赛季节' },
        { key: 'StageCount', label: '分站数', sortable: true },
        { key: 'start', label: '揭幕战', sortable: true },
        { key: 'close', label: '收官战', sortable: true },
      ],
      items: [],
      striped: true,
      bordered: true,
      pageOptions: [15, 25, 50],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    axios.get(Vue.prototype.$Api_Series_All)
      .then(response => {
        this.items = response.data
        this.totalRows = this.items.length
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCloseData(close, status) {
      let closeResult = ''
      if (status === 1) {
        closeResult = close
      } else {
        closeResult = '赛季进行中'
      }
      return closeResult
    },
    getSeacon(value) {
      return this.$getSeasonName(value)
    },
  },
}
</script>
